.topAnimation{
    height: max-content;
    position: absolute;
    width: 100%;
    top: 0px;
    z-index: 100;
}
@keyframes slow {
    from {height: 0px;}
    to {height:290px}
}
.navAnimation_1{
    justify-content: right!important;
    display: none;
}
.navAnimation_2{
    position: absolute;
    top: 56px;
    left: 0px;
    width: 100%;
    animation: slow 0.3s linear!important;
    height: max-content;
    overflow: hidden;
    display: grid;
    box-shadow: none!important;
    background-color: white;
}
.navAnimation > audio{
    margin: 3px;
    display: block!important;
    box-shadow: none!important;
    color: rgb(227, 27, 109);
    border: 1px solid while;
}
.navAnimation > a:focus{
    box-shadow: none!important;
}
.navAnimation > a{
    color: rgb(227, 27, 109);
}
.navAnimation > a:hover{
    border: 1px solid white;
    color: rgb(227, 27, 109);
}