.vl {
    border-left: 3px solid #e31b6d;
    height: 15px;
    display: inline-block;
}
.companyName{
    font-size: 16px;
    font-style: italic;
    color: #979797;
    font-weight: 400;
}