body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(233, 233, 220);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.myBlock{
  background-color: white;
}

checkbox {
  z-index: 1000;
}
.About_aboutMe__2MFCJ>div>div>p{
    margin: 0px;
}
.About_aboutMe__2MFCJ>h5{
    margin-top: 7px;
}
.About_firstLetter__15FYg{
    font-size: 35px;
    line-height: 40px;
}
.Experience_vl__2fi9g {
    border-left: 3px solid #e31b6d;
    height: 15px;
    display: inline-block;
}
.Experience_companyName__1Okod{
    font-size: 16px;
    font-style: italic;
    color: #979797;
    font-weight: 400;
}
.Landing_vmw__3Uze8{
    color: white;
    background-color: transparent;
    padding: 10px;
    font-size: 24px;
    border: 2px solid white;
    border-color: white;
    text-decoration: none;
}
.Landing_vmw__3Uze8:hover{
    background-color: #e31b6d;
    border-color: #e31b6d;
    transition: all 0.5s;
    color: white;
    text-decoration: none;
}
.Landing_vmwdiv__3alUY{
    flex-direction: initial;
    margin-top: 20px;
    justify-content: center;
    text-align: center;

}
.Landing_frontdiv__1xzmC{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    color: white;
    background-color: none;
}
.Landing_main1__3IuV3
{
    width: 100%;
    width: 100%;
    background-color: rgb(37, 41, 52);
}
.Landing_myVideoDiv__224Q8{
    /* width: 100vw; */
    height: 100vh;
    overflow: hidden;
}
.Landing_myVideoDiv__224Q8 > video{
    z-index: -10;
    min-height: 100%;
    min-width: 100%;
}
.FrontProfile_myDiv__2UuUt{
    position: relative;
    margin: 0px;
}
.FrontProfile_lalit__36cbn{
    color: #e31b6d;
}
.FrontProfile_myImageDiv__1iL32>img{
    width: 300px;
    border-radius: 100%;
}
.FrontProfile_profileIcons__3Au7O>a>img{
    width: 50px;
    padding-right: 5px;
}

@media (max-width: 575.98px) {
    .FrontProfile_myDetailDiv__TNvjG{
        text-align: center;
    }
}
.Navbar_topAnimation__1eQvj{
    height: -webkit-max-content;
    height: max-content;
    position: absolute;
    width: 100%;
    top: 0px;
    z-index: 100;
}
@-webkit-keyframes Navbar_slow__cUlbG {
    from {height: 0px;}
    to {height:290px}
}
@keyframes Navbar_slow__cUlbG {
    from {height: 0px;}
    to {height:290px}
}
.Navbar_navAnimation_1__36v41{
    justify-content: right!important;
    display: none;
}
.Navbar_navAnimation_2__2X5W0{
    position: absolute;
    top: 56px;
    left: 0px;
    width: 100%;
    -webkit-animation: Navbar_slow__cUlbG 0.3s linear!important;
            animation: Navbar_slow__cUlbG 0.3s linear!important;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    display: grid;
    box-shadow: none!important;
    background-color: white;
}
.Navbar_navAnimation__3n6r- > audio{
    margin: 3px;
    display: block!important;
    box-shadow: none!important;
    color: rgb(227, 27, 109);
    border: 1px solid while;
}
.Navbar_navAnimation__3n6r- > a:focus{
    box-shadow: none!important;
}
.Navbar_navAnimation__3n6r- > a{
    color: rgb(227, 27, 109);
}
.Navbar_navAnimation__3n6r- > a:hover{
    border: 1px solid white;
    color: rgb(227, 27, 109);
}
