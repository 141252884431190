.vmw{
    color: white;
    background-color: transparent;
    padding: 10px;
    font-size: 24px;
    border: 2px solid white;
    border-color: white;
    text-decoration: none;
}
.vmw:hover{
    background-color: #e31b6d;
    border-color: #e31b6d;
    transition: all 0.5s;
    color: white;
    text-decoration: none;
}
.vmwdiv{
    flex-direction: initial;
    margin-top: 20px;
    justify-content: center;
    text-align: center;

}
.frontdiv{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%,-50%);
    color: white;
    background-color: none;
}
.main1
{
    width: 100%;
    width: 100%;
    background-color: rgb(37, 41, 52);
}
.myVideoDiv{
    /* width: 100vw; */
    height: 100vh;
    overflow: hidden;
}
.myVideoDiv > video{
    z-index: -10;
    min-height: 100%;
    min-width: 100%;
}